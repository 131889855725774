import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Vimeo from "@u-wave/react-vimeo"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { HeroImage, Intro, Category, MobileDesign, Pagination, Section } from "@components/Work"
import { Carousel } from "@components/Carousel"
import { CursorMarker } from "@components/CursorMarker"
import { Arrow } from "@components/UI"
import { splitText } from "@utils"
import IconographyDesktop from "@svg/work/braen-stone/iconography-desktop.svg"
import IconographyTablet from "@svg/work/braen-stone/iconography-tablet.svg"
import IconographyMobile from "@svg/work/braen-stone/iconography-mobile.svg"
import workData from "@json/work.json"

const BraenStonePage = ({ data }) => {
  const {
    designOneImage,
    designTwoImage,
    designThreeImage,
    mobileOneImage,
    mobileTwoImage,
    mobileThreeImage,
    webpageOneImage,
    webpageTwoImage,
    photographyOneImage,
    photographyTwoImage,
    photographyThreeImage,
    galleryOneImage,
    galleryTwoImage,
    galleryThreeImage,
  } = data
  const { name, headline, theme, url } = workData["braen"]
  const { themeColor } = usePageSetup({
    themeColor: theme.background,
    navigationColor: theme.navigationColor,
    displayGeneralForm: false,
  })

  return (
    <Layout>
      <Seo title={name} description={headline} />
      {/* HERO */}
      <section className={themeColor}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26">
          <Hero.Title>
            <h1 className="tracking-normal text-white text-18">{name}</h1>
            <h2
              data-aos="stagger"
              className="text-work-headline mt-clamp-6-8"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-white": headline,
                }),
              }}
            />
          </Hero.Title>
        </Hero>
      </section>

      <Arrow colorTop="bg-white" colorBottom="bg-white" fillColor="text-white" />

      <main className="overflow-hidden">
        <HeroImage client="braen" />
        <Category>Website development, B2B branding</Category>
        <Intro>
          <Intro.Overview>
            <Intro.Title>Building a rock-solid B2B brand foundation.</Intro.Title>
            <div className="mt-10">
              <p>
                Braen Stone has been selling construction supplies since 1904, when legend has it Samuel Braen received
                the deed to a rock quarry to settle a gambling debt. Since then, Braen Stone has grown into a leading
                supplier of construction materials to builders across the New York metropolitan area.
              </p>
              <p>
                And while the company has modernized, their website still felt stuck in the past, so they tapped JK to
                overhaul everything from the content, to the search engine optimization, to how their brand’s visual
                identity was portrayed on the site.
              </p>
              <p>
                In the end, we delivered more than a new website—we created a new look and voice for the Braen Stone
                brand, ones that can be used well into the future.
              </p>
            </div>
          </Intro.Overview>
          <Intro.Deliverables url={url}>
            <li>Research</li>
            <li>Persona development</li>
            <li>Creative strategy</li>
            <li>Messaging</li>
            <li>Visual identity</li>
            <li>Custom iconography</li>
            <li>Photography</li>
            <li>UI/UX design</li>
            <li>Copywriting</li>
            <li>Website development</li>
          </Intro.Deliverables>
        </Intro>

        <section className="mt-clamp-34-29">
          <Section className="col-span-8 xl:col-span-7">
            <Section.Title>No stone left unturned.</Section.Title>
            <Section.Description>
              <p>
                The first order of business was to get to know the people who would use the site—contractors who use
                stone and asphalt products for everything from paving roads, to landscaping, to preventing shoreline
                erosion, and many other industrious uses.
              </p>
              <p>
                We did a deep dive and created personas and user journeys for each audience to ensure we would quickly
                address their needs on the site in a no-nonsense manner, as contractors consider time to be money and
                appreciate straightforward and quick online experiences.
              </p>
              <p>
                Using these insights, we then completely reworked the site structure and streamlined its contents,
                making it much easier to navigate and boosting its performance in organic search.
              </p>
            </Section.Description>
          </Section>
          <div className="relative mt-clamp-17-23">
            <div className="container flex justify-center">
              <div className="lg:w-10/12">
                <div data-aos="fade-up" className="overflow-hidden rounded-lg shadow">
                  <Vimeo video="605018595" controls={false} responsive muted autoplay />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="pt-clamp-44-82">
          <Section className="col-span-10 xl:col-span-9">
            <Section.Title>A design built to endure.</Section.Title>
            <Section.Description>
              <p>
                While this wasn’t an official rebranding project, Braen Stone wanted to use the design of their
                website—the centerpiece of their marketing efforts—to set a new tone for the brand. We obliged by
                flipping their color palette from light to dark, introducing more modern fonts, intensifying the color
                depth of their signature green accent and creating new rules for its use, introducing new brand
                textures, and helping them to find a new, more purposeful voice.
              </p>
              <p>
                It all added up to a contemporary new visual identity and voice that perfectly reflected the purposeful
                character of the organization and could be used across Braen Stone’s marketing footprint to better
                attract and engage the contractors who are the lifeblood of their business.
              </p>
            </Section.Description>
          </Section>
          <div className={`${theme.background} relative mt-clamp-24-30`}>
            <div className="absolute inset-0">
              <div className="container">
                <div className="w-full aspect-w-667 aspect-h-310 lg:aspect-w-1436 lg:aspect-h-240 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
            </div>
            <div className="container grid grid-cols-12 gap-x-2 lg:gap-x-4 grid-row-1">
              <div className="z-30 grid grid-cols-12 col-span-8 col-start-1 row-start-1 row-end-2 lg:col-end-7 lg:z-0">
                <div className="col-span-11 lg:col-span-11">
                  <div data-aos="fade-up">
                    <GatsbyImage
                      objectFit="contain"
                      className="overflow-hidden rounded-lg shadow"
                      image={getImage(designOneImage)}
                      alt="Webpage - 1"
                    />
                  </div>
                </div>
              </div>
              <div className="z-20 col-start-5 col-end-13 row-start-1 row-end-2 lg:col-start-4 lg:col-end-10">
                <div className="aspect-w-415 aspect-h-170 lg:aspect-w-710 lg:aspect-h-375"></div>
                <div className="lg:px-8">
                  <div data-aos="fade-up">
                    <GatsbyImage
                      objectFit="contain"
                      className="overflow-hidden rounded-lg shadow"
                      image={getImage(designTwoImage)}
                      alt="Webpage - 2"
                    />
                  </div>
                </div>
              </div>
              <div className="z-10 grid-cols-12 col-start-3 col-end-11 row-start-1 row-end-2 lg:col-start-7 lg:col-end-13 lg:grid">
                <div className="col-span-11 col-start-2">
                  <div className="aspect-w-441 aspect-h-669 lg:aspect-w-710 lg:aspect-h-110"></div>
                  <div data-aos="fade-up">
                    <GatsbyImage
                      objectFit="contain"
                      className="overflow-hidden rounded-lg shadow"
                      image={getImage(designThreeImage)}
                      alt="Webpage - 3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={`${theme.background} relative pt-clamp-15-42`}>
          <MobileDesign>
            <MobileDesign.Phone className="phone-1">
              <GatsbyImage
                objectFit="contain"
                className="overflow-hidden shadow rounded-3xl"
                image={getImage(mobileOneImage)}
                alt="Weichert mobile - 1"
              />
            </MobileDesign.Phone>
            <MobileDesign.Phone className="phone-2 sm:pt-clamp-35-62">
              <GatsbyImage
                objectFit="contain"
                className="overflow-hidden shadow rounded-3xl"
                image={getImage(mobileTwoImage)}
                alt="Weichert mobile - 2"
              />
            </MobileDesign.Phone>
            <MobileDesign.Phone className="phone-3">
              <GatsbyImage
                objectFit="contain"
                className="overflow-hidden shadow rounded-3xl"
                image={getImage(mobileThreeImage)}
                alt="Weichert mobile - 3"
              />
            </MobileDesign.Phone>
          </MobileDesign>
        </section>

        <section className={`${theme.background} relative pt-clamp-15-42`}>
          <div className="container relative flex flex-col-reverse grid-cols-2 sm:grid gap-x-4">
            <div className="absolute inset-0 flex">
              <div className="w-full mt-auto aspect-w-325 aspect-h-158 sm:aspect-w-1436 sm:aspect-h-266 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
            </div>
            <div className="z-10 flex transform -translate-y-10 sm:block sm:translate-y-0">
              <div className="hidden w-full sm:block aspect-w-710 aspect-h-194"></div>
              <div className="w-3/4 sm:w-full">
                <div data-aos="fade-up">
                  <GatsbyImage
                    className="overflow-hidden shadow rounded-2xl"
                    objectFit="contain"
                    image={getImage(webpageOneImage)}
                    alt="Webpage - 1"
                  />
                </div>
              </div>
            </div>
            <div className="flex sm:block">
              <div className="w-3/4 ml-auto sm:w-full">
                <div data-aos="fade-up">
                  <GatsbyImage
                    className="overflow-hidden shadow rounded-2xl"
                    objectFit="contain"
                    image={getImage(webpageTwoImage)}
                    alt="Webpage - 2"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-42-61">
          <Arrow colorTop="bg-gray-darkest" colorBottom="bg-white" fillColor="text-white" />
          <div className={`${theme.background} pt-clamp-44-62 pb-clamp-30-57`}>
            <div className="container flex flex-col-reverse grid-cols-2 lg:grid gap-x-4">
              <div className="mt-clamp-19-25 lg:mt-0">
                <div data-aos="fade-up">
                  <div className="display-desktop">
                    <IconographyDesktop />
                  </div>
                  <div className="display-tablet">
                    <IconographyTablet />
                  </div>
                  <div className="display-mobile">
                    <IconographyMobile />
                  </div>
                </div>
              </div>
              <div className="flex justify-center text-white">
                <div className="w-10/12 lg:ml-auto lg:w-5/6">
                  <Section.Title className="text-white">Sending the right signals.</Section.Title>
                  <Section.Description>
                    <p>
                      A strong visual identity is in the details, and for Braen Stone those details included a set of
                      custom icons that added a layer of polish to their site design, sending a subtle but meaningful
                      message about their sophistication as a business and a brand.
                    </p>
                  </Section.Description>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="pt-clamp-48-83">
          <Section className="col-span-10">
            <Section.Title>Capturing the essence of the brand.</Section.Title>
            <Section.Description>
              <p>
                A picture can say a thousand words, and Braen Stone wanted theirs to say all the right things. That’s
                why they asked us to travel to each of their quarries and capture high-quality photography and drone
                footage for use across the website and in other marketing materials. In the end we created a library of
                product shots, action stills, fly-by videos, and images of workers in action that artfully captured
                Braen Stone’s dedication to quality, safety, and efficiency, as well as the impressive scale of their
                business.
              </p>
            </Section.Description>
          </Section>
          <div className="container relative pt-clamp-12-14">
            <Carousel>
              <CursorMarker>
                <CursorMarker.Gallery />
              </CursorMarker>
              <Carousel.Swiper spaceBetween={16} centeredSlides={false}>
                <Carousel.Slide style={{ width: "auto" }}>
                  <div data-aos="fade-up" className="pointer-events-none w-76 h-92 md:w-138 md:h-138 lg:w-208 lg:h-150">
                    <GatsbyImage className="h-full" image={getImage(photographyOneImage)} alt="Photography 1" />
                  </div>
                </Carousel.Slide>
                <Carousel.Slide style={{ width: "auto" }}>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="200"
                    className="pointer-events-none w-76 h-92 md:w-110 md:h-138 lg:w-117 lg:h-150"
                  >
                    <GatsbyImage className="h-full" image={getImage(photographyTwoImage)} alt="Photography 2" />
                  </div>
                </Carousel.Slide>
                <Carousel.Slide style={{ width: "auto" }}>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="300"
                    className="pointer-events-none w-76 h-92 md:w-110 md:h-138 lg:w-117 lg:h-150"
                  >
                    <GatsbyImage className="h-full" image={getImage(photographyThreeImage)} alt="Photography 3" />
                  </div>
                </Carousel.Slide>
              </Carousel.Swiper>
            </Carousel>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="pt-clamp-48-67 pb-clamp-36-69">
          <div className="container flex flex-col lg:flex-row gap-x-4">
            <div className="flex flex-col w-full lg:w-1/2">
              <div className="flex justify-center">
                <div className="w-full sm:w-10/12 xl:w-4/6">
                  <Section.Title>The next step on the path.</Section.Title>
                  <Section.Description>
                    <p>
                      Braen Stone was so pleased with our collaborative process and our dedication to partnership that
                      they asked us to help reimagine the web presence of their consumer-facing brand, Braen Supply.
                      It’s a complex project, but one we’re eager to dive into as we continue to help define the future
                      of the Braen brand.
                    </p>
                  </Section.Description>
                </div>
              </div>
              <div className="flex items-center w-full h-full mt-12 sm:mt-15 lg:mt-0">
                <div className="w-full sm:w-1/2 lg:w-full">
                  <div data-aos="fade-up">
                    <div className="display-desktop">
                      <GatsbyImage
                        objectFit="contain"
                        className="w-full h-full"
                        image={getImage(galleryThreeImage)}
                        alt="Gallery 3"
                      />
                    </div>
                    <div className="display-mobile-tablet">
                      <GatsbyImage
                        objectFit="contain"
                        className="w-full h-full"
                        image={getImage(galleryOneImage)}
                        alt="Gallery 1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid w-full mt-4 lg:w-1/2 gap-y-4 lg:mt-0">
              <div className="flex justify-center">
                <div className="w-full sm:ml-auto sm:w-5/6 lg:ml-0 lg:w-4/6">
                  <div data-aos="fade-up">
                    <div className="display-desktop">
                      <GatsbyImage
                        objectFit="contain"
                        className="w-full h-full"
                        image={getImage(galleryOneImage)}
                        alt="Gallery 1"
                      />
                    </div>
                    <div className="display-mobile-tablet">
                      <GatsbyImage
                        objectFit="contain"
                        className="w-full h-full"
                        image={getImage(galleryTwoImage)}
                        alt="Gallery 2"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full">
                <div className="w-full sm:w-5/6 lg:w-full">
                  <div data-aos="fade-up">
                    <div className="display-desktop">
                      <GatsbyImage
                        objectFit="contain"
                        className="w-full h-full"
                        image={getImage(galleryTwoImage)}
                        alt="Gallery 2"
                      />
                    </div>
                    <div className="display-mobile-tablet">
                      <GatsbyImage
                        objectFit="contain"
                        className="w-full h-full"
                        image={getImage(galleryThreeImage)}
                        alt="Gallery 3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-white" fillColor="text-white" />

        <Pagination client="braen" />
      </main>
    </Layout>
  )
}

export default BraenStonePage

export const braenStoneQuery = graphql`
  query BraenStonePageQuery {
    designOneImage: file(relativePath: { eq: "work/braen-stone/design-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    designTwoImage: file(relativePath: { eq: "work/braen-stone/design-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    designThreeImage: file(relativePath: { eq: "work/braen-stone/design-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobileOneImage: file(relativePath: { eq: "work/braen-stone/mobile-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobileTwoImage: file(relativePath: { eq: "work/braen-stone/mobile-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobileThreeImage: file(relativePath: { eq: "work/braen-stone/mobile-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    webpageOneImage: file(relativePath: { eq: "work/braen-stone/webpage-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    webpageTwoImage: file(relativePath: { eq: "work/braen-stone/webpage-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    photographyOneImage: file(relativePath: { eq: "work/braen-stone/photography-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    photographyTwoImage: file(relativePath: { eq: "work/braen-stone/photography-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    photographyThreeImage: file(relativePath: { eq: "work/braen-stone/photography-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    galleryOneImage: file(relativePath: { eq: "work/braen-stone/gallery-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    galleryTwoImage: file(relativePath: { eq: "work/braen-stone/gallery-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    galleryThreeImage: file(relativePath: { eq: "work/braen-stone/gallery-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
